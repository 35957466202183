<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <!-- <b-row><b-col class="ft-weight"> TERM & CONDITION</b-col></b-row> -->
    <div>
      <h1 class="mr-sm-4 header-tablepage">TERM & CONDITION</h1>
    </div>

    <div class="title-panel mt-3">Terms and Policy details</div>
    <b-container class="no-gutters bg-white">
      <b-row>
        <b-col class="mt-3">
          <TextEditorsTiny
            v-model="form.template"
            :value="form.template"
            @onDataChange="(val) => (form.template = val)"
            textFloat=""
            placeholder="TERM & CONDITION"
          />
        </b-col>
      </b-row>
    </b-container>

  
    <!-- <div class="space-1"></div> -->
    <FooterAction @submit="UpdateTerms" routePath="/setting" />
  </div>
</template>

<script>
import TextEditorsTiny from "@/components/inputs/TextEditorsTiny";
import OtherLoading from "@/components/loading/OtherLoading";

export default {
  components: {
    TextEditorsTiny,
    OtherLoading,
  },
  data() {
    return {
      form: {
        template: null,
        version: 0,
        status: 0,
      },
      updateTerms: {},
      isLoading: true,
    };
  },
  async created() {
    await this.getDetailTerms();
  },
  methods: {
    async getDetailTerms() {
      await this.$store.dispatch("getDetailTerms");
      const data = this.$store.state.setting.stateDetailTerms;
      if (data.result === 1) {
        this.form.template = data.detail;
        this.isLoading = false;
      }
    },
    async UpdateTerms() {
      await this.$store.dispatch("UpdateTerms", this.form);
      const data = this.$store.state.setting.stateUpdateTermsCondition;
      if (data.result === 1) {
        this.updateTerms = data.detail;
        this.$swal("Update Success!", {
          icon: "success",
        }).then(function () {
          window.location.href = `/setting/term`;
        });
      } else {
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    closeAutomation() {
      this.$router.push("/setting/term");
    },
  },
};
</script>

<style lang="scss" scoped>
.ft-weight {
  font-weight: 600;
  font-size: 16px;
}
.bg-purple {
  text-align: left;
  border: none;
  background: var(--secondary-color);
  border-radius: 0px;
  color: #000;
  font-weight: 600;
  padding: 10px 0;
  font-size: 13px;
}
::v-deep .text-editor-custom {
  margin-top: -22px;
}
.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  padding: 10px;
  background: #fff;
}
.btn-cancel-automation {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.space-1 {
  margin-top: 50px;
}
</style>
